import { Middleware } from "@reduxjs/toolkit";
import { shouldSyncSciSettings, buildSyncedSciSettings } from "@/utils/sci-utils";
import { updateSelfCheckIn } from "@/api/nextme/self-check-ins";
import { mutate } from 'swr'

export const sciMiddleware: Middleware = (store) => (next) => async (action) => {
  if (action.type === 'selected/syncSciSettings') {

    // check if sci needs updating
    const { sci, isUsingCustomQueueSelector, customQueueCFId } = action.payload
    const currentState = store.getState()
    const shouldSync = shouldSyncSciSettings(sci, isUsingCustomQueueSelector, customQueueCFId)

    if (shouldSync) {
      // build synced settings
      const newSci = buildSyncedSciSettings(sci, isUsingCustomQueueSelector, customQueueCFId, currentState?.queueSelect?.isUsingBothQueueSelectors)
      newSci.settings.publicFields.email = false
      delete newSci.business
      delete newSci.schedule

      // update sci
      // if it is possible that the logged in user does not have permissions to do this
      // need to move this to a server->server token authorized api call
      // until then, it will fail in background
      try {
        await updateSelfCheckIn(newSci)
        await mutate(`/nextme/self-check-ins`)
      } catch (e) {
        console.error(e)
      }
    }
  }

  next(action)
}