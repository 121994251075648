export const PartyStatusEnum = {
  cancelled: "Cancelled",
  cancelled_by_guest: "Cancelled (By Guest)",
  checked_in: "Checked In",
  completed: "Completed",
  confirmed: "Confirmed",
  in_service: "In Service",
  no_show: "No Show",
  notified: "Notified",
  returning: "Returning",
  scheduled: 'Scheduled',
  waiting: "Waiting",
  walked_out: "Walked Out",
}

export const INACTIVE_STATUSES_LIST = [
  "cancelled",
  "no_show",
  "walked_out",
  "in_service",
  "completed",
]

export const ACTIVE_STATUSES_LIST = [
  "waiting",
  'returning',
  'scheduled',
  "notified",
  "checked_in",
  "confirmed",
  "cancelled_by_guest",
]

export const READY_STATUS_LIST = [
  'notified',
  'returning',
  'checked_in',
]

export const WAITING_STATUS_LIST = [
  'waiting',
  'confirmed',
  'scheduled',
]

export const PartyRemovalOptions = {
  cancelled: "Cancelled",
  no_show: "No Show",
  walked_out: "Walked Out",
}

export const PartyStatusNotBookingEnum = {
  cancelled: "Cancelled",
  cancelled_by_guest: "Cancelled (By Guest)",
  checked_in: "Checked In",
  completed: "Completed",
  in_service: "In Service",
  no_show: "No Show",
  notified: "Notified",
  returning: "Returning",
  scheduled: 'Scheduled',
  waiting: "Waiting",
  walked_out: "Walked Out",
}
