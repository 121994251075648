import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  bannerMessageId: null,
  bannerLink: null,
  bannerLinkText: null,
  bannerMessage: "",
  bannerType: "error",
  isBannerOpen: false,
  dismissedBanners: [],
}

const bannerSlice = createSlice({
  name: "banner",
  initialState,
  reducers: {
    setBanner: (state, action) => {
      const {
        messageId = null,
        isOpen = false,
        link = null,
        linkText = null,
        message = "",
        type = "success",
        dismissedBanners,
      } = action.payload

      state.bannerMessageId = messageId
      state.isBannerOpen = isOpen
      state.bannerLink = link
      state.bannerLinkText = linkText
      // state.dismissedBanners.push(dismissedBanners)
      state.bannerMessage = message
      state.bannerType = type
    },
    setIsBannerOpen: (state, action) => {
      state.isBannerOpen = action.payload
    },
    setDismissedBanners: (state, action) => {
      state.dismissedBanners.push(action.payload)
    }
  },
})

export const { setBanner, setIsBannerOpen, setDismissedBanners } = bannerSlice.actions
export default bannerSlice.reducer
