export const AlertTypeEnum = {
  error: "error",
  info: "info",
  success: "success",
  warning: "warning",
}

export const AlertMessageEnum = {
  errors: {
    generic: {
      title: "Something went wrong",
      text: "Please reload the page and try again.",
    },
  },
  updated: { title: "Success", text: "updated" },
}
