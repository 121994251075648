import { createSlice } from "@reduxjs/toolkit"
/**
 * Manages state of queue selection behavior.
 * Cases:
 * If a location has a custom queue select field:
 * Display the custom selector instead of the
 */

const initialState = {
  isUsingCustomQueueSelector: false, // location has a custom queue select field
  customQueueSelectOptions: null, // the cached options of the queue select field
  standardQueueSelectOptions: null, // the cached options of the standard queue select field, includes only queues not covered in custom selector options
  locationQueueIds: [], // queues available for the location, for convenience
  isUsingBothQueueSelectors: false, // isUsingCustomQueueSelector is true, but there are queues not included in custom select options, display the standard selector also
}

const queueSelectSlice = createSlice({
  name: 'queueSelect',
  initialState,
  reducers: {
    setIsUsingCustomQueueSelector: (state, { payload }) => {
      state.isUsingCustomQueueSelector = payload
    },
    setIsUsingBothQueueSelectors: (state, { payload }) => {
      state.isUsingBothQueueSelectors = payload
    },
    setCustomQueueSelectOptions: (state, { payload }) => {
      state.customQueueSelectOptions = payload
    },
    setStandardQueueSelectOptions: (state, { payload }) => {
      state.standardQueueSelectOptions = payload
    },
    setLocationQueueIds: (state, { payload }) => {
      state.locationQueueIds = payload
    },
  },
})

export const {
  setIsUsingCustomQueueSelector,
  setCustomQueueSelectOptions,
  setIsUsingBothQueueSelectors,
  setStandardQueueSelectOptions,
  setLocationQueueIds,
} = queueSelectSlice.actions

export default queueSelectSlice.reducer