import { createCss } from "@stitches/react"
import utils from "@/utils/styling-utils"
import { createTheme } from "@mui/system"

const _theme = {
  colors: {
    "midnight": "rgba(5, 19, 54, 1)",
    "twilight": "rgba(32, 43, 126, 1)",
    blue: "rgba(0, 91, 255, 1)",
    green: "rgba(37, 201, 85, 1)",
    yellow: "rgba(255, 193, 101, 1)",
    red: "rgba(232, 87, 87, 1)",
    slate: "rgba(29, 29, 29, 1)",
    "gray-dark": "rgba(117, 117, 117, 1)",
    "gray-light": "rgba(240, 240, 240, 1)",
    white: "rgba(255, 255, 255, 1)",
    border: "hsla(0, 0%, 74%, 1)",
    "shadow-2dp-0": "rgba(58, 58, 68, 0.24)",
    "shadow-2dp-1": "rgba(90, 91, 106, 0.24)",
    "shadow-4dp-0": "rgba(58, 58, 68, 0.2)",
    "shadow-4dp-1": "rgba(90, 91, 106, 0.2)",
    "shadow-8dp-0": "rgba(58, 58, 68, 0.16)",
    "shadow-8dp-1": "rgba(90, 91, 106, 0.16)",
    "shadow-16dp-0": "rgba(58, 58, 68, 0.12)",
    "shadow-16dp-1": "rgba(58, 58, 68, 0.12)",
    "shadow-24dp-0": "rgba(90, 91, 106, 0.08)",
    "shadow-24dp-1": "rgba(90, 91, 106, 0.08)",
  },
  space: {
    px: "1px",
    0: "0",
    1: "0.25rem", // 4px
    2: "0.5rem", // 8px
    3: "0.75rem", // 12px
    4: "1rem", // 16px
    5: "1.25rem", // 18px
    6: "1.5rem", // 24px
    8: "2rem", // 32px
    10: "2.5rem", // 40px
    12: "3rem", // 48px
    16: "4rem", // 64px
    20: "5rem", // 80px
    24: "6rem", // 96px
    32: "8rem",
    40: "10rem",
    48: "12rem",
    56: "14rem",
    64: "16rem",
  },
  fontSizes: {
    "3xs": "0.5rem", // 8px
    "2xs": "0.625rem", // 10px
    xs: "0.75rem", // 12px
    sm: "0.875rem", // 14px
    body: "1em", // 16px
    lg: "1.25rem", // 20px
    xl: "1.5rem", // 24px
    "2xl": "2.125rem", // 34px
    "3xl": "3rem", // 48px
    "4xl": "3.75rem", // 60px
    "5xl": "6rem", // 96px
  },
  fonts: {
    default: '"Montserrat", Helvetica, Arial, apple-system, sans-serif',
  },
  fontWeights: {
    normal: 400,
    medium: 500,
    "semi-bold": 600,
    bold: 700,
  },
  lineHeights: {
    0: "0",
    1: "1", // 16px
    2: "1.12rem", // 18px
    3: "1.25rem", // 20px
    4: "1.5rem", // 24px
    8: "2rem", // 32px
    10: "2.5rem", // 40px
  },
  letterSpacings: {
    0: "-2.6",
    1: "-2.5",
    2: "0.75",
    // 3: '2.2',
    3: "2.75",
    4: "3.3",
    5: "5.4",
    6: "6",
    15: "15",
  },
  sizes: {
    px: "1px",
    0: "0",
    1: "0.25rem", // 4px
    2: "0.5rem", // 8px
    3: "0.75rem", // 12px
    4: "1rem", // 16px
    5: "1.25rem", // 18px
    6: "1.5rem", // 24px
    8: "2rem", // 32px
    10: "2.5rem", // 40px
    12: "3rem", // 48px
    16: "4rem", // 64px
    20: "5rem", // 80px
    24: "6rem", // 96px
    32: "8rem", // 128px
    40: "10rem", // 160px
    48: "12rem", // 192px
    56: "14rem", // 224px
    64: "16rem", // 256px

    // Grid Settings
    "grid-gutter-x": "1.5rem",
    "grid-gutter-y": 0,
    "grid-max-width-sm": "540px",
    "grid-max-width-md": "720px",
    "grid-max-width-lg": "960px",
    "grid-max-width-xl": "1140px",
    "grid-max-width-xxl": "1320px",
  },
  borderWidths: {
    0: "1px",
  },
  borderStyles: {
    0: "solid $colors$border",
  },
  radii: {
    0: "2px",
    1: "4px",
    2: "8px",
    3: "16px",
    4: "24px",
    5: "32px",
    16: "48px",
  },
  shadows: {
    "2dp": "0 1px 2px $colors$shadow-2dp-0, 0 2px 4px $colors$shadow-2dp-1",
    "4dp": "0 2px 4px $colors$shadow-4dp-0, 0 4px 8px $colors$shadow-4dp-1",
    "8dp": "0 4px 8px $colors$shadow-8dp-0, 0 8px 16px $colors$shadow-8dp-1",
    "16dp":
      "0 8px 16px $colors$shadow-16dp-0, 0 16px 32px $colors$shadow-16dp-1",
    "24dp":
      "0 12px 24px $colors$shadow-24dp-0, 0 24px 48px $colors$shadow-24dp-1",
  },
  zIndices: {
    auto: "auto",
    0: "0",
    100: "100",
    200: "200",
    300: "300",
    400: "400",
    500: "500",
    600: "600",
    700: "700",
    800: "800",
    900: "900",
  },
  transitions: {},
}

export const { styled, css, global, keyframes, getCssString, theme } =
  createCss({
    theme: _theme,
    media: {
      xs: "(max-width: 576px)",
      sm: "(min-width: 576px)",
      gapMax: "(min-width: 600px)", //gap between the usual breakpoints and the custom breakpoints in the styling
      md: "(min-width: 768px)",
      lg: "(min-width: 992px)",
      xl: "(min-width: 1200px)",
      xxl: "(min-width: 1400px)",
      landscape: "(orientation: landscape)",
      portrait: "(orientation: portrait)",
    },
    utils,
  })

export const globalStyles = global({
  "*, *:before, *:after": {
    margin: 0,
    padding: 0,
    boxSizing: "inherit",
  },
  html: {
    boxSizing: "border-box",
  },
  body: {
    color: "$slate",
    fontSize: "1rem",
    fontFamily: "$default",
    height: "100vh",
  },
})

export const muiTheme = createTheme(_theme)
