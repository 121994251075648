import { createSlice } from "@reduxjs/toolkit"

interface IState {
  geolocation: { lat?: number; long?: number; acc?: number; error?: any }
}

const initialState: IState = {
  geolocation: {},
}

const partySlice = createSlice({
  name: "party",
  initialState,
  reducers: {
    setGeolocation: (state, action) => {
      state.geolocation = action.payload
    },
    resetGeolocation: (state) => {
      state.geolocation = initialState.geolocation
    },
  },
})

export const { resetGeolocation, setGeolocation } = partySlice.actions
export default partySlice.reducer
