import { LocalStorageKeys } from "@/consts/local-storage-keys"
import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  hasOnlyOneLocation: false,
  isCustomFieldFormOpen: false,
  isUsingSharedSettings: true,
  isQueueFormOpen: false,
  isUsingCustomQueueSelector: false,
  setIsFilterModalOpen: false,
}

//* break this out into individual pages as the app becomes more complex
const ManagePagesSlice = createSlice({
  name: "manage",
  initialState,
  reducers: {
    setHasOnlyOneLocation(state, { payload }) {
      state.hasOnlyOneLocation = payload
    },
    setIsCustomFieldFormOpen(state, { payload }) {
      state.isCustomFieldFormOpen = payload
    },
    setIsUsingSharedSettings(state, { payload }) {
      state.isUsingSharedSettings = payload

      localStorage.setItem(LocalStorageKeys.isUsingSharedSettings, payload)
    },
    setIsQueueFormOpen(state, { payload }) {
      state.isQueueFormOpen = payload
    },
    setIsUsingCustomQueueSelector(state, { payload }) {
      state.isUsingCustomQueueSelector = payload
    },
  },
})

export const {
  setHasOnlyOneLocation,
  setIsCustomFieldFormOpen,
  setIsUsingSharedSettings,
  setIsQueueFormOpen,
  setIsUsingCustomQueueSelector,
} = ManagePagesSlice.actions

export default ManagePagesSlice.reducer
