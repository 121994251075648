import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isDialogOpen: false,
    size: '',
    feature: '',
}

const featureGateSlice = createSlice({
    name: 'featureDialog',
    initialState,
    reducers: {
        setDialog: (state, action) => {
            const {
                isDialogOpen = false,
                size = '',
                feature = ''
            } = action.payload

            state.isDialogOpen = isDialogOpen
            state.size = size
            state.feature = feature
        },
        setIsDialogOpen: (state, action) => {
            state.isDialogOpen = action.payload
        }
    }
})
export const { setDialog, setIsDialogOpen } = featureGateSlice.actions
export default featureGateSlice.reducer