import axios from "axios"
import { captureException } from "@sentry/nextjs"
import { toSnakeCase, toCamelCase } from "case-converter"

export default function setAxiosConfig() {
  axios.defaults.baseURL = process.env.NEXT_PUBLIC_API_BASE_URL + "/"
  axios.defaults.withCredentials = true

  axios.interceptors.request.use((config) => ({
    ...config,
    data: toSnakeCase(config.data),
    params: toSnakeCase(config.params),
  }))

  axios.interceptors.response.use(
    (res) => ({
      ...res,
      data: toCamelCase(res.data),
    }),
    (error) => {
      // https://axios-http.com/docs/handling_errors
      const { response } = error

      const result = {
        ...error,
        response: {
          ...(response || {}),
          data: {
            ...toCamelCase(response?.data),
            _error: response?.data?.non_field_errors || [],
          },
        },
      }

      if (error?.response?.status !== 401) {
        captureException(JSON.stringify(error))
      }

      return Promise.reject(result)
    }
  )
}
