import { createSlice } from "@reduxjs/toolkit";
import { Account } from "@/api/interface/account";
import { Business } from "@/api/interface/business";

interface SelectedState {
  currentUser: Partial<Account>
  currentBusiness: Partial<Business> & { industry?: string, locationCount?: number, defaultCountryISO?: string }
}

const initialState: SelectedState = {
  currentUser: {
    id: null,
    businessId: null,
    firstName: null,
    lastName: null,
    email: null,
    status: null,
    createdAt: null,
    roles: [],
    permissions: [],
    phone: null
  },
  currentBusiness: {
    name: null,
    phone: null,
    status: null,
    locationCount: null,
    industry: null,
    defaultCountryISO: 'us'
  }
}

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      const {
        currentUser,
        currentBusiness
      } = action.payload

      state.currentUser = currentUser
      state.currentBusiness = currentBusiness
    },
  },
})

export const {
  setUserData,
} = userSlice.actions

export default userSlice.reducer