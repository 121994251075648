import { createSlice } from "@reduxjs/toolkit"
import {
  ACTIVE_STATUSES_LIST,
  INACTIVE_STATUSES_LIST,
} from "@/consts/PartyStatusEnum"
import { APIFilterParams } from "@/api/interface/utils"
import { isValidValue } from "@/utils/validators"
import { CustomField } from "@/api/interface/custom-field"
import { isDeepEqual } from "@/utils/util-functions"

export interface WaitlistState {
  filters: {
    isHistory: boolean
    isBooking: boolean
    status: string
    customFields: Array<{
      id: CustomField["id"],
      values: CustomField["value"],
    }>
    onlyShow?: string
    startsAfter?: string
    endsBefore?: string
  }
  searchTerm: string
  isFiltered: boolean
  isBookingView: boolean
  isFilterModalOpen: boolean
  activePartiesParams: APIFilterParams
  historicalPartiesParams: APIFilterParams
  partiesParams: APIFilterParams
}

const listFilter = { 
  isHistory: false,
  isBooking: null,
  status: ACTIVE_STATUSES_LIST.join(","), 
  customFields: [],
  onlyShow: 'active',
  startsAfter: null,
  endsBefore: null,
}

const defaultPartiesParams = {
  page: {
    size: 25,
    number: 1,
  },
}

const defaultActivePartiesParams = {
  filters: {
    status: ACTIVE_STATUSES_LIST.join(","),
    onlyShow: 'active',
    startsAfter: null,
    endsBefore: null,
  },
}

const defaultHistoricalPartiesParams = {
  isBookingView: false,
  sort: "-parties.updated_at",
  filters: {
    status: INACTIVE_STATUSES_LIST.join(","),
    onlyShow: 'active',
    startsAfter: null,
    endsBefore: null,
  },
}

const initialState: WaitlistState = {
  filters: listFilter,
  isBookingView: false,
  isFiltered: false,
  isFilterModalOpen: false,
  searchTerm: "",
  activePartiesParams: defaultActivePartiesParams,
  historicalPartiesParams: defaultHistoricalPartiesParams,
  partiesParams: defaultPartiesParams,
}

const hasFilters = (state: WaitlistState) => {
  const { status, customFields } = state.filters
  const filtersWoutBookings = {
    ...state.filters,
    endsBefore: null,
    startsAfter: null,
    isBooking: null,
  }
  // const isEqualToListFilter = isDeepEqual(filtersWoutBookings, listFilter)
  const hasStatusFilters =
    status !== defaultHistoricalPartiesParams.filters.status &&
    status !== defaultActivePartiesParams.filters.status
  const hasCfFilters = customFields?.length && customFields.length > 0

  return hasStatusFilters || hasCfFilters
}

const hasSearchTerm = (state: WaitlistState) => {
  return state.searchTerm.length > 0
}

const waitlistSlice = createSlice({
  name: "waitlist",
  initialState,
  reducers: {
    resetFilters: (state) => {
      state.filters = {
        ...listFilter,
        isHistory: state.filters.isHistory,
        status: state.filters.isHistory ? defaultHistoricalPartiesParams.filters.status : defaultActivePartiesParams.filters.status
      }
      state.isFiltered = false
      state.isBookingView = false
    },
    clearFiltersAndSearch: (state) => {
      state.filters = {
        ...listFilter,
        isHistory: state.filters.isHistory,
        status: state.filters.isHistory ? defaultHistoricalPartiesParams.filters.status : defaultActivePartiesParams.filters.status
      }
      state.isFiltered = false
      state.searchTerm = ""
    },
    setFilters: (state, action) => {
      const { payload } = action
      const { isHistory, status } = payload

      if (isHistory && status.length === 0) {
        state.filters = {
          ...payload,
          status: defaultHistoricalPartiesParams.filters.status,
        }
      } else if (!isHistory && status.length === 0) {
        state.filters = {
          ...payload,
          status: defaultActivePartiesParams.filters.status,
        }
      } else {
        state.filters = payload
      }

      state.isFiltered = hasFilters(state)
    },
    toggleHistoryFilter: (state) => {
      state.filters.isHistory = !state.filters.isHistory

      // reset the standard status view so filter form populates properly
      state.filters.status = state.filters.isHistory ? defaultHistoricalPartiesParams.filters.status : defaultActivePartiesParams.filters.status
      state.isBookingView = state.filters.isHistory ? false : state.isBookingView
      state.filters.isBooking = null
      state.filters.startsAfter = null
      state.filters.endsBefore = null
    },
    setIsFilterModalOpen: (state, action) => {
      state.isFilterModalOpen = action.payload
    },
    setPageNumber: (state, action) => {
      state.partiesParams.page.number = action.payload
    },
    setPageSize: (state, action) => {
      state.partiesParams.page.size = action.payload
    },
    setPartiesFilter: (state, action) => {
      state.partiesParams.filter = action.payload
    },
    setActivePartiesParams: (state, action) => {
      state.activePartiesParams = action.payload
    },
    setHistoricalPartiesParams: (state, action) => {
      state.historicalPartiesParams = action.payload
    },
    setIsFiltered: (state, action) => {
      state.isFiltered = action.payload
    },
    setIsBookingView: (state, action) => {
      state.isBookingView = action.payload
    },
    setIsHistory: (state, action) => {
      if (!action.payload) {
        state.filters.isHistory = false
        state.filters.status = defaultActivePartiesParams.filters.status
      } else {
        state.filters.isHistory = true
        state.filters.status = defaultHistoricalPartiesParams.filters.status
        state.isBookingView = false
      }
    },
    resetBookings: (state) => {
      state.filters.isBooking = null
      state.filters.startsAfter = null
      state.filters.endsBefore = null
      state.isBookingView = false
    }
  },
})

export const {
  resetFilters,
  clearFiltersAndSearch,
  setFilters,
  toggleHistoryFilter,
  resetBookings,
  setIsBookingView,
  setIsFilterModalOpen,
  setIsHistory,
  setPageNumber,
  setPageSize,
  setPartiesFilter,
  setActivePartiesParams,
  setHistoricalPartiesParams,
  setIsFiltered,
} = waitlistSlice.actions

export default waitlistSlice.reducer
