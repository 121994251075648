import '@/styles/sanitize.css'
import '@/styles/bootstrap.css'
import '@/styles/Utilities.css'
import '@/styles/Datepicker.css'
import '@/styles/tailwind-globals.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-dropzone-uploader/dist/styles.css'
import 'emoji-mart/css/emoji-mart.css'
import React, { useEffect, useMemo, useState } from 'react'
import { CookiesProvider } from 'react-cookie'
import { useRouter } from 'next/router'
import { SWRConfig } from 'swr'
import useAccount from '@/hooks/api-hooks/useAccount'
import { getRouteData } from '@/api/nextme/swr'
import setAxiosConfig from '@/utils/axios-config'
import LoggedOutLayout from '@/components/layouts/LoggedOutLayout'
import styles from '@/styles/LoggedOutLayout.module.scss'
import typo from '@/styles/Typography.module.scss'
import colors from '@/styles/Colors.module.scss'
import { globalStyles } from '@/styles/theme'
import Head from 'next/head'
import { Provider } from 'react-redux'
import store from '@/store/index'
import classNames from 'classnames'
import { AccountStatusEnum } from '@/consts/AccountStatusEnum'
import { StylesProvider } from '@material-ui/core'
import { DragReorderContextProvider } from '@/components/common/DragReorder/DragReorderProvider'

function App(ctx: any) {
  const router = useRouter()

  useEffect(() => {
    //* make sure only lowercase files in /pages can be accessed
    if (/[A-Z][a-z]*$/.test(router.pathname)) {
      router.replace(router.pathname.split('/').slice(0, -1).join('/'))
    }
  }, [router])

  setAxiosConfig()
  globalStyles()

  const { Component, pageProps } = ctx

  const publicHome = '/'
  const privateHome = '/waitlist'
  const invitedHome = '/account'

  //* routes that can be accessed regardless of auth status
  const publicRoutes = [
    '/token-login',
    '/logout',
    '/self-check-in/[locationId]',
    '/signup',
    '/signup/email-verified',
    '/reset-password',
    '/vwr',
    '/wait-lists/[waitlistId]',
  ]

  //* routes that will internally redirect the user
  const selfRedirectRoutes = [
    '/logout',
    '/reset-password',
    '/signup/email-verified',
  ]

  //* routes that can ONLY be accessed as part of the new user signup process
  const signupRoutes = [
    '/signup/business',
    '/signup/verify-account',
    '/signup/email-verified',
  ]

  //TODO: this should be direct to the plans & payment page
  const pastDueRoutes = ['/business/']

  //* routes that can ONLY be accessed if the user is logged out
  const unauthRoutes = ['/']

  const isPublicRoute = publicRoutes.includes(router.pathname)
  const isUnauthRoute = unauthRoutes.includes(router.pathname)
  const isSignupRoute = signupRoutes.includes(router.pathname)
  const isSelfRedirectRoute = selfRedirectRoutes.includes(router.pathname)
  const isPastDueRoute = pastDueRoutes.includes(router.pathname)
  const isPrivateRoute = !isPublicRoute && !isUnauthRoute
  const isLogoutRoute = router.pathname === '/logout'

  const [redirectTo, setRedirectTo] = useState(null)

  const {
    isValidating,
    error: accountError,
    account,
  } = useAccount(isPublicRoute)

  const isAuthenticated = useMemo(
    () => !accountError && account?.createdAt,
    [account, accountError]
  )

  //* check account to determine redirect flow during signup process
  const accountStatus = useMemo(() => {
    if (!isAuthenticated) {
      return null
    }

    //* UX: this specifically comes before the other statuses
    if (account?.status === AccountStatusEnum.invited) {
      return AccountStatusEnum.invited
    }

    if (!account?.emailVerifiedAt) {
      return AccountStatusEnum.unverified
    }

    if (!account?.businessId) {
      return AccountStatusEnum.incomplete
    }

    return account?.status
  }, [account, isAuthenticated])

  useEffect(() => {
    // prevent users from getting stuck on the logout route if session expires
    if (!isValidating && !accountStatus && isLogoutRoute) {
      setRedirectTo(publicHome)
    }
    if (isValidating) return

    // TODO if a user is being redirected due to session timeout,
    // TODO we should redirect them back to the original page request after login
    //* we don't know whether to redirect the user or not until the account has loaded
    if (!isValidating) {
      //* set redirect if user is somewhere they shouldn't be
      switch (accountStatus) {
        case AccountStatusEnum.disabled:
          if (!isPublicRoute) {
            setRedirectTo('/account-disabled')
          }
          break
        case AccountStatusEnum.invited:
          setRedirectTo(invitedHome)
          break
        case AccountStatusEnum.enabled:
          //* some pages represent a "redirecting" state as user feedback before redirecting
          if (!isSelfRedirectRoute && (isSignupRoute || isUnauthRoute)) {
            setRedirectTo(privateHome)
          }
          break
        case AccountStatusEnum.incomplete:
          setRedirectTo('/signup/business')
          break
        case AccountStatusEnum.unverified:
          //TODO: show account verify modal instead of this page (https://nextmeapp.atlassian.net/browse/NEX-69)
          setRedirectTo('/signup/verify-account')
          break
        case AccountStatusEnum.frozen:
        case AccountStatusEnum.pastDue:
          if (!isPublicRoute || !isPastDueRoute) {
            setRedirectTo('/account-disabled')
          }
          break
        default:
          if (isPrivateRoute && !isAuthenticated) {
            setRedirectTo(publicHome)
          } else {
            setRedirectTo(null)
          }
          break
      }
    }
  }, [
    accountStatus,
    isPrivateRoute,
    isPublicRoute,
    isSelfRedirectRoute,
    isSignupRoute,
    isUnauthRoute,
    isValidating,
  ])

  const shouldRedirect = useMemo(
    () => redirectTo && redirectTo !== router.pathname,
    [redirectTo, router]
  )

  useEffect(() => {
    if (router.asPath === '/token-logging-in') {
      router.replace('/account')
      router.events.on('routeChangeComplete', () => setRedirectTo(null))
    }
    if (shouldRedirect) {
      console.debug(`Redirecting from ${router.pathname} to ${redirectTo}`)
      router.replace(redirectTo)

      //* To avoid flashing the login form, wait until redirect has completed
      router.events.on('routeChangeComplete', () => setRedirectTo(null))
    }
  }, [redirectTo, router, shouldRedirect])

  const isUnauthUserAtPrivateRoute = !isAuthenticated && isPrivateRoute
  const isAuthUserAtUnauthRoute = isAuthenticated && isUnauthRoute
  const isEnabledUserAtSignupRoute =
    accountStatus === AccountStatusEnum.enabled &&
    isSignupRoute &&
    !isSelfRedirectRoute

  const showLoadingScreen = useMemo(
    () =>
      !isPublicRoute &&
      (isValidating ||
        shouldRedirect ||
        isAuthUserAtUnauthRoute ||
        isUnauthUserAtPrivateRoute ||
        isEnabledUserAtSignupRoute),
    [
      isPublicRoute,
      isAuthUserAtUnauthRoute,
      isEnabledUserAtSignupRoute,
      isUnauthUserAtPrivateRoute,
      isValidating,
      shouldRedirect,
    ]
  )

  return (
    <Provider store={store}>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"
        />
      </Head>
      <StylesProvider injectFirst>
        <CookiesProvider>
          <SWRConfig
            value={{
              // provider: swrLocalStorageProvider,
              fetcher: getRouteData,
              // TODO: expand on this - add snackbar or something
              onError: (err) => {
                // https://swr.vercel.app/docs/error-handling#global-error-report
                console.debug('SWR Err:', JSON.stringify(err))
              },
            }}
          >
            {showLoadingScreen ? (
              <LoggedOutLayout title="Loading...">
                <main className={styles.body}>
                  <div className={styles.left}>
                    <div className={styles.text}>
                      <h4 className={classNames(typo.h4, colors.white)}>
                        Loading, please wait...
                      </h4>
                    </div>
                  </div>
                </main>
              </LoggedOutLayout>
            ) : (
              <DragReorderContextProvider>
                <Component {...pageProps} account={account} />
              </DragReorderContextProvider>
            )}
          </SWRConfig>
        </CookiesProvider>
      </StylesProvider>
    </Provider>
  )
}

export default App
