import React from "react"
import Link from "next/link"
import styles from "@/styles/Navbar.module.css"
import { useRouter } from "next/router"

function Navbar() {
  const router = useRouter()

  const isHomePage = router.pathname === "/"
  const isLogoutPage = router.pathname === "/logout"
  const isSignupPage = router.pathname === "/signup"

  return (
    <div className={styles.navbar}>
      <div className={styles.logo}>
        <Link href="/">
          <a>
            <img
              src="/logos/nextme/nextme-logo-light.svg"
              alt="NextMe Logo"
              style={{ height: 50 }}
            />
          </a>
        </Link>
      </div>
      <div className={styles.buttonGroup}>
        {(isHomePage || isSignupPage) && (
          <Link href="/signup" passHref>
            <button id="signup-btn" className={styles.signupBtn}>
              Sign Up
            </button>
          </Link>
        )}
        {(isHomePage || isSignupPage) && (
          <Link href="/" passHref>
            <button id="login-btn" className={styles.loginBtn}>
              Log In
            </button>
          </Link>
        )}
        {!(isHomePage || isSignupPage) && (
          <Link href="/logout" passHref>
            <button
              id="login-btn"
              className={styles.loginBtn}
              disabled={isLogoutPage}
            >
              Log Out
            </button>
          </Link>
        )}
      </div>
    </div>
  )
}

export default Navbar
