import { Middleware } from "@reduxjs/toolkit";
import dayjs from "dayjs";

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

const identifyMiddleware: Middleware = store => next => action => {

  if (action.type === 'user/setUserData') {
    const {
      currentUser,
      currentBusiness,
    } = action.payload

    window.dataLayer.push({
      event: 'user_data_updated',
      user_id: currentUser.id,
      name: `${currentUser.firstName} ${currentUser.lastName}`,
      email: currentUser.email,
      status: currentUser.status,
      created_at: dayjs(currentUser.createdAt).unix(),
      role: currentUser.roles[0],
      phone: currentUser.phone ? `+1${currentUser.phone}` : null,
      company_id: currentUser.businessId,
      company_name: currentBusiness.name,
      company_phone: `+1${currentBusiness.phone}`,
      company_status: currentBusiness.status,
      company_industry: currentBusiness.industry,
      company_locations: currentBusiness.locationCount,
      plan_type: currentBusiness.planType,
      trial_ends_at: currentBusiness.trialEndsAt,
    })
  }
  next(action)
}

export {
  identifyMiddleware,
}