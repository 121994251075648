import useSWR from "swr"
import { getAccount } from "@/api/nextme/account"
import { refreshIntervals } from "@/consts/swr"
import { Account } from "@/api/interface/account"

export default function useAccount(isPublic?: boolean) {
  const {
    data: account,
    error,
    isValidating,
    mutate: mutateAccount,
  } = useSWR(
    "/nextme/account/",
    () => (!isPublic ? getAccount() : ({} as Account)),
    {
      dedupingInterval: 10000, // 10s
      shouldRetryOnError: false,
      // errorRetryCount: 2,
      refreshInterval: refreshIntervals.verySlow,
      revalidateOnFocus: false,
    }
  )

  return {
    isValidating: !account && isValidating,
    // TODO: consider how this would affect offline mode and such
    // make user = null if this happens we are potentially logged out
    // most times we want to gracefully fail and show cached data
    account,
    mutateAccount,
    error,
  }
}
