const msPerSec = 1000
const secPerMin = 60

const minutes = (mins: number) => msPerSec * secPerMin * mins
const seconds = (secs: number) => msPerSec * secs

export const refreshIntervals = {
  tenSeconds: seconds(10),
  quicker: seconds(30),
  quick: minutes(1),
  standard: minutes(5),
  slow: minutes(10),
  verySlow: minutes(15),
}

export const dedupingIntervals = {
  quick: seconds(5),
  standard: seconds(10),
  slow: seconds(30),
  verySlow: seconds(60),
}
