import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  alertDuration: null,
  alertAnchor: "bottom-left",
  alertTitle: "",
  alertMessage: "",
  alertType: "success",
  alertRestoreRoute: null,
  disableClickAway: false,
  isAlertOpen: false,
  keepOpen: false,
}

const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    setAlert: (state, action) => {
      const {
        anchor = "bottom-left",
        duration = null,
        isOpen = false,
        title = "",
        message = "",
        type = "success",
        restoreRoute = null,
        disableClickAway = false,
        keepOpen = false,
      } = action.payload

      state.isAlertOpen = isOpen
      state.alertAnchor = anchor
      state.alertDuration = duration
      state.alertKeepOpen = keepOpen
      state.alertTitle = title
      state.alertMessage = message
      state.alertType = type
      state.alertRestoreRoute = restoreRoute
      state.disableClickAway = disableClickAway
    },
    setIsAlertOpen: (state, action) => {
      state.isAlertOpen = action.payload
    },
  },
})

export const { setAlert, setIsAlertOpen } = alertSlice.actions
export default alertSlice.reducer
