import { createSlice } from "@reduxjs/toolkit"

interface SelectedState {
  editingCFId?: string
  editingLocationId?: string
  editingQueueId?: string
}

const initialState: SelectedState = {
  editingCFId: null,
  editingLocationId: null,
  editingQueueId: null,
}

const selectedSlice = createSlice({
  name: "selected",
  initialState,
  reducers: {
    setEditingCFId: (state, action) => {
      state.editingCFId = action.payload
    },
    setEditingLocationId: (state, action) => {
      state.editingLocationId = action.payload
    },
    setEditingQueueId: (state, action) => {
      state.editingQueueId = action.payload
    },
    syncSciSettings: (state, action) => {

    },
  },
})

export const { setEditingCFId, setEditingLocationId, setEditingQueueId, syncSciSettings } =
  selectedSlice.actions

export default selectedSlice.reducer
