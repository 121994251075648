import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  swrParams: {
    include: ["address", "media", "subscriptions"],
  },
}

const BusinessSlice = createSlice({
  name: "business",
  initialState,
  reducers: {},
})

export default BusinessSlice.reducer
