import { Box, Snackbar } from "@mui/material"
import MuiAlert from "@material-ui/lab/Alert"
import AlertTitle from "@material-ui/lab/AlertTitle"
import IconButton from "@material-ui/core/IconButton"
import axios from "axios"
import { X } from "react-feather"
import colors from "@/styles/Colors.module.scss"
import { useDispatch, useSelector } from "react-redux"
import { setIsAlertOpen } from "@/store/slices/alertSlice"
import { AlertTypeEnum } from "@/consts/alert-messages"
import { printAxiosErrors } from "@/utils/util-functions"

const Alert = () => {
  const dispatch = useDispatch()

  const {
    isAlertOpen,
    alertAnchor,
    alertDuration,
    alertKeepOpen,
    alertType,
    alertTitle,
    alertMessage,
    alertRestoreRoute,
    disableClickAway,
  } = useSelector((state) => state.alert)

  const callApi = async () => {
    try {
      const url = `v2/${alertRestoreRoute}`
      await axios.patch(url)

      dispatch(
        setAlert({
          alertKeepOpen,
          isOpen: true,
          message: "Data has been restored!",
          restoreRoute: null,
          title: "Data restored",
          type: AlertTypeEnum.success,
        })
      )
    } catch (err) {
      console.error(printAxiosErrors(err))

      dispatch(
        setAlert({
          isOpen: true,
          title: err.response?.data?.message,
          type: "error",
          message: printAxiosErrors(err, true),
        })
      )
    }
  }

  return (
    <Snackbar
      sx={{ maxWidth: "75vw", bottom: 24, right: 24 }}
      open={isAlertOpen}
      autoHideDuration={alertKeepOpen ? null : alertDuration || 5000}
      onClose={() => dispatch(setIsAlertOpen(false))}
      anchorOrigin={{
        vertical: alertAnchor.split("-")[0],
        horizontal: alertAnchor.split("-")[1],
      }}
      ClickAwayListenerProps={
        disableClickAway ? { onClickAway: () => {} } : null
      }
    >
      <MuiAlert
        elevation={5}
        severity={alertType}
        action={
          <>
            {alertRestoreRoute && (
              <button
                sx={{
                  fontFamily: "Montserrat",
                  borderRadius: 99,
                  border: "none",
                  outline: "none",
                  cursor: "pointer",
                  marginRight: 8,  
                }}
                onClick={() => {
                  callApi()
                }}
              >
                Undo
              </button>
            )}
            <IconButton
              aria-label="close"
              color="inherit"
              size="medium"
              onClick={() => {
                dispatch(setIsAlertOpen(false))
              }}
            >
              <X />
            </IconButton>
          </>
        }
      >
        {alertTitle && (
          <AlertTitle style={{ fontFamily: "Montserrat", fontWeight: 700 }}>{alertTitle}</AlertTitle>
        )}
        <div style={{ fontFamily: "Montserrat", fontWeight: 500, whiteSpace: "pre-wrap" }}>{alertMessage}</div>
      </MuiAlert>
    </Snackbar>
  )
}

export default Alert
