import { createSlice } from "@reduxjs/toolkit"
import { DailyStatsApiParams } from "@/api/interface/daily-stats"

const initialState: DailyStatsApiParams = {
  filter: {
    id: null,
    locationId: null,
    queueId: null,
    startDay: null,
    endDay: null,
  }
}

const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filter = { ...action.payload }
    },
    setFilterStartDay: (state, action) => {
      state.filter.startDay = action.payload
    },
    setFilterEndDay: (state, action) => {
      state.filter.endDay = action.payload
    },
    setFilterLocation: (state, action) => {
      state.filter.locationId = action.payload
    }
  },
})

export const { setFilter, setFilterStartDay, setFilterEndDay, setFilterLocation} =
analyticsSlice.actions

export default analyticsSlice.reducer