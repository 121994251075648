import { createSlice } from "@reduxjs/toolkit"
import { LocalStorageKeys } from "@/consts/local-storage-keys"
import { UserRole } from "@/consts/UserRolesEnum"

interface SelectedState {
  businessId?: string
  customFieldId?: string
  industryId?: string
  locationId?: string
  partyId?: string
  queueId?: string
  userRole?: UserRole
  currentPartyQueueId?: string
  publicWaitlistId?: string
  bookingTimeRangeName?: string
}

const initialState: SelectedState = {
  businessId: null,
  customFieldId: null,
  industryId: null,
  locationId: null,
  partyId: null,
  queueId: null,
  userRole: null,
  currentPartyQueueId: null,
  publicWaitlistId: null,
  bookingTimeRangeName: null,
}

const selectedSlice = createSlice({
  name: "selected",
  initialState,
  reducers: {
    resetSelectedSlice: () => initialState,
    setSelectedBookingTimeRangeName: (state, action) => {
      state.bookingTimeRangeName = action.payload
    },
    setSelectedBusinessId: (state, action) => {
      state.businessId = action.payload
    },
    setSelectedCustomFieldId: (state, action) => {
      state.customFieldId = action.payload
    },
    setSelectedIndustryId: (state, action) => {
      state.industryId = action.payload
    },
    setSelectedLocationId: (state, action) => {
      state.locationId = action.payload

      localStorage.setItem(LocalStorageKeys.selectedLocationId, action.payload)
    },
    setSelectedPartyId: (state, action) => {
      state.partyId = action.payload
    },
    setSelectedQueueId: (state, action) => {
      state.queueId = action.payload
    },
    setSelectedUserRole: (state, action) => {
      state.userRole = action.payload
    },
    setCurrentPartyQueueId: (state, action) => {
      state.currentPartyQueueId = action.payload
    },
    setPublicWaitlistId: (state, action) => {
      state.publicWaitlistId = action.payload
    }
  },
})

export const {
  resetSelectedSlice,
  setSelectedBookingTimeRangeName,
  setSelectedBusinessId,
  setSelectedCustomFieldId,
  setSelectedIndustryId,
  setSelectedLocationId,
  setSelectedPartyId,
  setSelectedQueueId,
  setSelectedUserRole,
  setCurrentPartyQueueId,
  setPublicWaitlistId,
} = selectedSlice.actions

export default selectedSlice.reducer
