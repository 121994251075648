import { isUsingCustomQueueSelector } from './../../../../utils/custom-field-utils';
import { SelectableType } from "@/api/interface/utils"
import { Industry } from "@/api/interface/industry"
import { CustomField } from "@/api/interface/custom-field"
import { SelfCheckIn } from "@/api/interface/self-check-in"
import {
  ScheduleApiDay,
  ScheduleDay,
} from "@/components/pages/manage/self-check-in/interface"
import {
  convert12HTo24H,
  convert24HTo12H,
  getMeridiemFromTime,
} from "@/utils/time-utils"
import { closedMessageDefault } from '@/consts/sci-messages'

export const buildEmptySci = (
  businessId: string,
  locationId: string,
  visibleCustomFields: Array<CustomField>,
  timezone: string,
  locationAddressId: string,
  isUsingCustomQueueSelector: boolean,
): SelfCheckIn => ({
  id: null,
  businessId,
  sciableType: "location" as SelectableType,
  sciableId: locationId,
  waitTimeMethod: "off" as Industry["defaultLinePositionMethod"],
  scheduleDays: emptySciScheduleDays,
  schedule: {
    businessId,
    items: emptySciScheduleDays.map((x) => convertFormDayToScheduleDay(x)),
    name: "",
    scheduleableType: "self_check_in",
    scheduleableId: null,
    timezone,
  },
  geofence: {
    addressId: locationAddressId || "",
    radiusMi: 1,
    isEnabled: false,
  },
  settings: {
    version: 1,
    btnBackgroundColor: "",
    btnTextColor: "",
    displayWaitTime: false,
    includeTermsCheckbox: false,
    terms: "",
    subtitle: "",
    title: "",
    closedMessageTitle: closedMessageDefault.TITLE,
    closedMessageSubtitle: closedMessageDefault.SUBTITLE,
    useWaitlistHours: true,
    overrides: {
      locationName: "",
      queueName: "",
    },
    publicFields: {
      firstName: true,
      lastName: true,
      phone: true,
      queueSelect: !isUsingCustomQueueSelector,
      email: false,
    },
    customFields: {
      ...Object.fromEntries(visibleCustomFields?.map((x) => [x.id, true])),
    },
  },

})

export const buildDefaultFormDay = (weekday = ""): ScheduleDay => ({
  id: null,
  day: weekday,
  startTime: "9:00",
  startTimeM: "AM",
  endTime: "5:00",
  endTimeM: "PM",
  note: "",
})

export const emptySciScheduleDays = [
  { ...buildDefaultFormDay("sunday") },
  { ...buildDefaultFormDay("monday") },
  { ...buildDefaultFormDay("tuesday") },
  { ...buildDefaultFormDay("wednesday") },
  { ...buildDefaultFormDay("thursday") },
  { ...buildDefaultFormDay("friday") },
  { ...buildDefaultFormDay("saturday") },
]

export const buildDisabledFormDay = (weekday = "", id): ScheduleDay => ({
  id: null,
  day: weekday,
  startTime: "12:00",
  startTimeM: "AM",
  endTime: "11:59",
  endTimeM: "PM",
  note: "",
})

export const disabledSciScheduleDays = [
  { ...buildDefaultFormDay("sunday") },
  { ...buildDefaultFormDay("monday") },
  { ...buildDefaultFormDay("tuesday") },
  { ...buildDefaultFormDay("wednesday") },
  { ...buildDefaultFormDay("thursday") },
  { ...buildDefaultFormDay("friday") },
  { ...buildDefaultFormDay("saturday") },
]

export const toggleFormDayDefaults = (day: ScheduleDay, enabled: Boolean): ScheduleDay => ({
  id: day.id || null,
  day: day.day,
  startTime: enabled ? '9:00' : '12:00',
  startTimeM: 'AM',
  endTime: enabled ? '5:00' : '11:59',
  endTimeM: 'PM',
  note: ''
})

export const buildEnabledScheduleDays = (items, enabled) => {
  const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
  let newItems

  if (items) {
    const existingItems = items.map(item => toggleFormDayDefaults(item, enabled))
    if (existingItems.length < days.length) {
      newItems = days.map(day => {
        const existingRecord = existingItems.find(item => item.day === day)
        if (existingRecord) {
          return existingRecord
        }
        return {
          id: null,
          day: day,
          startTime: enabled ? '9:00' : '12:00',
          startTimeM: 'AM',
          endTime: enabled ? '5:00' : '11:59',
          endTimeM: 'PM',
          note: ''
        }
      })
    }
  }
  return days.map(day => ({
    id: null,
    day: day,
    startTime: enabled ? '9:00' : '12:00',
    startTimeM: 'AM',
    endTime: enabled ? '5:00' : '11:59',
    endTimeM: 'PM',
    note: ''
  }))
}

export const buildDisabledScheduleDays = () => {

}

export const convertFormDayToScheduleDay = (
  day: ScheduleDay
): ScheduleApiDay => ({
  id: day.id || null,
  scheduleId: null,
  category: "day",
  day: day.day,
  open: convert12HTo24H(day.startTime, day.startTimeM),
  close: convert12HTo24H(day.endTime, day.endTimeM),
  data: day.note,
})

export const convertScheduleDayToFormDay = (
  apiScheduleDay: ScheduleApiDay
): ScheduleDay => ({
  id: apiScheduleDay.id,
  day: apiScheduleDay.day,
  startTime: convert24HTo12H(apiScheduleDay.open), //* special time of 0:00 for overnight (i.e. wed 13:00-24:00, thurs 00:00-02:00)
  startTimeM: getMeridiemFromTime(apiScheduleDay.open),
  endTime: convert24HTo12H(apiScheduleDay.close), //* special time of 24:00
  endTimeM: getMeridiemFromTime(apiScheduleDay.close),
  note: apiScheduleDay.data,
})

/*
//* Exception format (need date picker first)
{
    "id": "97a6eb3e-c900-4517-ae25-47530eb25a36",
    "schedule_id": "97a6eb3e-b67c-4793-ad45-949355bbf2ef",
    "category": "exception",
    "day": "12-25",
    "open": "",
    "close": "",
    "data": "Closed on Christmas"
},
*/

// @TODO - this shouldn't automatically set setting to true
export const transformCFsForSettings = (cfArray: Array<CustomField>) => {
  return Object.fromEntries(
    cfArray?.map(cf => [cf.id, cf.visibility === 'all' || cf.visibility === 'self_check_in'])
  )
}

export const orderByKeys = (obj) => {
  return Object.keys(obj).sort()
    .reduce((sortedObj, key) => {
      sortedObj[key] = obj[key]
      return sortedObj
    }, {})
}

export const areObjectsIdentical = (obj1, obj2) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2)
}


