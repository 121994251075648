import Head from "next/head"
import Navbar from "@/components/common/HeaderNav/LoggedOutNav"
import Alert from "@/components/common/Alert"

export default function LoggedOutLayout({
  title = "NextMe",
  children,
}) {
  return (
    <>
      <Head>
        <title>{title ? `${title} |` : ""} NextMe</title>
      </Head>
      <Alert />
      <Navbar />
      {children}
    </>
  )
}
