import { combineReducers, configureStore } from "@reduxjs/toolkit"
import { TypedUseSelectorHook, useSelector } from "react-redux"
import alertReducer from "@/store/slices/alertSlice"
import selectedReducer from "@/store/slices/selectedSlice"
import uiReducer from "@/store/slices/uiSlice"
import waitlistReducer from "@/store/slices/pages/waitlistSlice"
import businessReducer from "@/store/slices/pages/businessSlice"
import manageReducer from "@/store/slices/pages/manageSlice"
import settingsReducer from "@/store/slices/pages/settingsSlice"
import partyReducer from "@/store/slices/partySlice"
import featureGateReducer from "@/store/slices/featureGateSlice"
import bannerReducer from "@/store/slices/bannerSlice"
import userReducer from "@/store/slices/userSlice"
import analyticsReducer from '@/store/slices/pages/analyticsSlice'
import queueSelectReducer from '@/store/slices/queueSelectSlice'
import { identifyMiddleware } from "./middleware/identifyMiddleware"
import { sciMiddleware } from "./middleware/sciMiddleware"

const appRootStore = configureStore({
  reducer: combineReducers({
    alert: alertReducer,
    featureDialog: featureGateReducer,
    banner: bannerReducer,
    selected: selectedReducer,
    ui: uiReducer,
    party: partyReducer,
    queueSelect: queueSelectReducer,
    user: userReducer,
    pages: combineReducers({
      waitlist: waitlistReducer,
      business: businessReducer,
      manage: manageReducer,
      settings: settingsReducer,
      analytics: analyticsReducer,
    }),
  }),
  devTools: process.env.NEXT_PUBLIC_WEB_BASE !== 'https://web.nextmeapp.com',
  //TODO: add error handler to dispatch
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([identifyMiddleware, sciMiddleware]),
})

export type AppRootState = ReturnType<typeof appRootStore.getState>

export const useTypedSelector: TypedUseSelectorHook<AppRootState> = useSelector

export default appRootStore
