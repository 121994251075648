import { Party } from '@/api/interface/party'
import { dayjs } from '@/utils/dayjs'
import { DurationUnitType } from "dayjs/plugin/duration"

//TODO timezone calculation
export const getHour12To24H = (parsedHr: number, meridiem?: "AM" | "PM") => {
  if (parsedHr === 12 && meridiem === 'AM') {
    return 0
  }

  return parsedHr + (meridiem === "PM" && parsedHr < 12 ? 12 : 0)
}

export const convert12HTo24H = (time: string, meridiem?: "AM" | "PM") => {
  const minute = time?.split(":")[1] || "00"

  const parsedHr = parseInt(time?.split(":")[0] || "00", 10)
  const hour = getHour12To24H(parsedHr, meridiem)

  return `${hour.toString().length === 2 ? hour : "0" + hour}:${minute}`
}

export const getHour24HTo12H = (parsedHr: number) => {
  if (parsedHr === 0) {
    return 12
  }
  return parsedHr - (parsedHr > 12 && parsedHr !== 24 ? 12 : 0)
}

export const convert24HTo12H = (time: string) => {
  const minute = time?.split(":")[1] || "00"

  const parsedHr = parseInt(time?.split(":")[0] || "00", 10)
  const hour = getHour24HTo12H(parsedHr)

  return `${hour}:${minute}`
}

export const getMeridiemFromTime = (time: string) =>
  parseInt(time?.split(":")?.[0], 10) < 12 ? "AM" : "PM"

export const getDurationInSeconds = (minutes, seconds) => {
  return (minutes/60) + seconds
}

export const standardizeMonthsCoversion = (durationString: string) => {
  // each whole month adds a multiple of 12 minutes
  // if duration string is months and minutes only
  // and minutes are = months * 12
  // strip minutes for display
  const durationArr = durationString.split(' ')
  if (durationArr.length !== 2) {
    return durationString
  }
  // durationString is not months and minutes
  if (!durationArr[0].includes('mo') || !durationArr[1].includes('m')) {
    return durationString
  }

  // parse number value from minutes and months
  const monthValue = parseInt(durationArr[0].match(/(\d+)/)[1])
  const minutesValue = parseInt(durationArr[1].match(/(\d+)/)[1])

  if (minutesValue === monthValue * 12) {
    return `${monthValue}mo`
  }
  return null
}

/**
 * TODO - we've changed wait time input from number field to dropdown of explicit values.
 * This may not be necessary anymore. Need to test scenarios and remove if applicable.
 * 
 * Month adjustments are to hide the rounding done by dayjs when formatting daya for display.
 * This is a hack to have a clean display without introducing our own rounding errors into the conversion.
 * This probably should be rewritten, but attempts on short timeline before release just introduced
 * different rounding errors. Keys are year value, and months/days are the months and day values that
 * equal one additional year with dayjs rounding error. So 0, 12, 0 = 1 year and 1, 11, 25 = 2 years, etc.
 * To be used for display formatting only.
 */
const MONTH_ADJUSTMENTS = {
  0: {
    months: 12,
    days: 0,
  },
  1: {
    months: 11,
    days: 25,
  },
  2: {
    months: 11,
    days: 20,
  },
  3: {
    months: 11,
    days: 15,
  },
  4: {
    months: 11,
    days: 10,
  },
  5: {
    months: 11,
    days: 5,
  },
  6: {
    months: 11,
    days: 0,
  },
  7: {
    months: 10,
    days: 25,
  },
  8: {
    months: 10,
    days: 20,
  },
  9: {
    months: 10,
    days: 15,
  }
}

const getShouldAdjustMonthsDays = (unformattedDuration) => {
  const years = unformattedDuration.get('years')
  const months = unformattedDuration.get('months')
  const days = unformattedDuration.get('days')

  if (months === MONTH_ADJUSTMENTS[years]?.months && days === MONTH_ADJUSTMENTS[years]?.days) {
    return true
  }
  
  return false
}

export const getDisplayTimeDuration = (duration, units, places) => {

  if (!duration || duration === 0 || typeof duration !== 'number') {
    return '0m'
  }
  const isNegativeDuration = duration < 0
  
  const unformatted = dayjs.duration(Math.abs(duration), units)
  const formatted = dayjs.duration(Math.abs(duration), units).format('Y[y] M[mo] D[d] H[h] m[m] s[s]')
  const shouldAdjustMonthsDays = getShouldAdjustMonthsDays(unformatted)

  // remove days from the array, add replace with weeks and days, return nonzero values
  const weeksDaysReplaced = formatted
    .split(' ')
    .reduce((acc: string[], d: string) => {
      const splitRegex = /^(\d*)([a-z]*)$/
      const regexOutput = d.match(splitRegex)
      const [value, units] = [regexOutput[1], regexOutput[2]]
      const parsedValue = parseInt(value)

      if (units !== 'd' && parsedValue > 0 && units !== 'y' && !shouldAdjustMonthsDays) {
        acc.push(d)
      }
      if (units === 'd' && shouldAdjustMonthsDays) {
        acc.push('')
      }
      if (units === 'mo' && shouldAdjustMonthsDays) {
        acc.push('')
      }
      if (units === 'y' && shouldAdjustMonthsDays) {
        acc.push(`${parsedValue + 1}y`)
      }
      if (units === 'y' && parsedValue !== 0 && !shouldAdjustMonthsDays) {
        acc.push(d)
      }

      if (units === 'd' && parsedValue > 0 && !shouldAdjustMonthsDays) {
        const [weeks, dayRemainder] = [Math.floor(parsedValue / 7), parsedValue % 7]
          if (weeks > 0) acc.push(`${weeks}w`)
          if (dayRemainder > 0) acc.push(`${dayRemainder}d`)
      }
      return acc
    }, [])
  
  const weeksDaysReplacedStr = weeksDaysReplaced.slice(0, places).join(' ')
  
  const standardizedMonths = standardizeMonthsCoversion(weeksDaysReplacedStr)
  if (standardizedMonths) {
    return standardizedMonths
  }
  
  return `${isNegativeDuration ? '-' : ''}${weeksDaysReplacedStr}`
}

export const filterUnits = (formattedDuration, excludedUnits = []) => {
  const splitRegex = /^(\d*)([a-z]*)$/
  const arr = formattedDuration.split(' ').filter(item => {
    const regexOutput = item.match(splitRegex)
    const [value, units] = [regexOutput[1], regexOutput[2]]
    return !excludedUnits.includes(units)
  })
  if (arr.length < 1 && excludedUnits.includes('s')) {
    return '<1m'
  }
  return arr.join(' ')
}

export const allUnitOptions = [
  {
    label: "Seconds",
    value: "seconds",
  },
  {
    label: "Minutes",
    value: "minutes",
  },
  {
    label: "Hours",
    value: "hours",
  },
  {
    label: "Days",
    value: "days",
  },
  {
    label: "Months",
    value: "months",
  },
  {
    label: "Years",
    value: "years",
  },
]

export const timeUnitsEnum = ["seconds", "minutes", "hours", "days", "months", "years"]

export const round2Decimals = (value) => {
  return Math.round((value + Number.EPSILON) * 100) / 100
}

export const convertTime = (
  value: number,
  sourceUnits: DurationUnitType,
  targetUnits: DurationUnitType
) => {
  if (!value) {
    return 0
  }
  const sourceValue = dayjs.duration(value, sourceUnits)
  return sourceValue.as(targetUnits)
}

export const findBestTimeUnits = (value: number, units: string) => {
  const thresholds = {
    seconds: 1000,
    minutes: 60,
    hours: 60,
    days: 24,
    months: 30,
  }

  if (value === 0) {
    return {
      value: 0,
      units: 'minutes',
    }
  }

  if (!value || !units) {
    return {
      value: '',
      units: '',
    }
  }
  
  const bestFit = Object.keys(thresholds).reduce((acc, d: DurationUnitType) => {
    
    const timeConvertedToDUnits = convertTime(value, units as DurationUnitType, d)
    
    if (timeConvertedToDUnits >= 1) {
      acc = {
        value: timeConvertedToDUnits,
        units: d,
      }
    }
    return acc
  }, { value: 0, units: 'minutes' })

  return {
    value: bestFit.value,
    units: bestFit.units,
  }
}

export const getJoinedAtDisplayTime = (partyCreatedAt, timeZone = "America/Los_Angeles") => {
  if (!partyCreatedAt) {
    return ''
  }
  const todayStr = dayjs()
    .tz(timeZone)
    .format("M/D/YYYY")
    .toString()
  const joinedAtDateStr = dayjs(partyCreatedAt)
    .format("M/D/YYYY")
    .toString()
  const joinedSameDay = todayStr === joinedAtDateStr

  if (joinedSameDay) {
    return dayjs(partyCreatedAt)
      .format("h:mm a").toString()
  }
  
  return dayjs(partyCreatedAt)
    .format("h:mm a [on] M/D/YYYY")
    .toString()
}

export const cleanTimezone = (dateString: string) => {
  const lastChar = dateString?.charAt(dateString?.length - 1)
  return lastChar === 'Z'
    ? dateString?.substring(0, dateString?.length - 1)
    : dateString
}

export const validateHrsMinsTimeString = (str: string) => {
  const regex = /^[0-9]{2}\:[0-9]{2}$/
  return !!str.match(regex)
}

export const validateHrsMinsSecsTimeString = (str: string) => {
  const regex = /^[0-9]{2}\:[0-9]{2}\:[0-9]{2}$/
  return !!str.match(regex)
}

export const unformattedTimeDiff = (time1, time2) => {
  return dayjs(time1).diff(dayjs(time2))
}

const addLeadingZero = (value: string) => {
  if (parseInt(value) < 10) {
    return String(value).padStart(2, '0')
  }
  return value
}

export const getBestFit5Minutes = (minutes?: string) => {
  if (!minutes) {
    return '00'
  }

  const mins = parseInt(minutes)
  const bestFit = addLeadingZero((Math.ceil(mins / 5) * 5).toString())
  return bestFit
}

export const getClosest5MinsToNow = () => {
  const now = dayjs()
  const mins = now.get('minutes').toString()
  const bestFit = parseInt(getBestFit5Minutes(mins))
  const nowUpdated = now.set('minute', bestFit).set('second', 0)
  return {
    date: nowUpdated.format('YYYY-MM-DD'),
    time: nowUpdated.format('HH:mm:ss'),
    dateTime: nowUpdated.format('YYYY-MM-DD HH:mm:ss'),
  }
}

export const convertTimezone = (datetimeString: string, timezone: string) => {
  const datetime = dayjs(datetimeString)
  if (datetime.isValid()) {
    return datetime.tz(timezone).format('YYYY-MM-DD HH:mm:ss')
  }
  return ''
}

export const DATETIME_CONVERSION_EXCEPTIONS = [
  'bookingTimeLocal',
]

// use this when we want to convert all top level datetime strings to local timezone
export const convertAllDatetimeKeysTimezone = (items: any[], timezone: string) => {
  const convertedItems = items?.map((item) => {
    let convertedItem = { ...item }
    for (const key in item) {
      const valueAsDatetime = typeof item[key] === 'string' && dayjs(item[key])
      
      if (valueAsDatetime && valueAsDatetime?.isValid() && !DATETIME_CONVERSION_EXCEPTIONS.includes(key)) {
        const hasTimezone = item[key]?.charAt(item[key]?.length - 1) === 'Z'
        convertedItem[key] = hasTimezone ? convertTimezone(item[key], timezone) : item[key]
      }
    }
    return convertedItem
  })
  return convertedItems
}

// use this when party.createdAt is the only date that needs local conversion
export const convertPartyCreatedAt = (items: Party[], timezone: string) => {
  const convertedItems = items?.map((item) => {
    const convertedCreatedAt = convertTimezone(item.createdAtDate, timezone)
    return {
      ...item,
      createdAt: convertedCreatedAt,
      createdAtDate: convertedCreatedAt,
    }
  })
  return convertedItems
}