import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  isLoading: false,
  isBackdropOpen: false,
  isSearchbarOpen: false,
  isSidebarOpen: false,
  isSlideOpen: false,
}

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setIsBackdropOpen: (state, action) => {
      state.isBackdropOpen = action.payload
    },
    setIsSearchbarOpen: (state, action) => {
      state.isSearchbarOpen = action.payload
    },
    setIsSidebarOpen: (state, action) => {
      state.isSidebarOpen = action.payload
    },
    setIsSlideOpen: (state, action) => {
      state.isSlideOpen = action.payload
    },
  },
})

export const {
  setIsLoading,
  setIsBackdropOpen,
  setIsSearchbarOpen,
  setIsSidebarOpen,
  setIsSlideOpen,
} = uiSlice.actions
export default uiSlice.reducer
